export default {
  unknown_error: 'Unknown server error occurred. Please contact us via feedback form.',
  captcha_error: 'Please confirm that you\'re a human!',
  continue: 'Continue',
  submit: 'Submit',
  learn_more: 'Learn more',
  clear: 'Clear',
  search: 'Search',
  search_by_number: 'Search by codes',
  product_code: 'Code',
  product_name: 'Name',
  no_vat: 'w/o VAT',
  price: 'Price',
  price_rub: 'Price, RUB',
  sum_rub: 'Total price, RUB',
  total_vat_rub: 'Total price with VAT, RUB',
  total_rub: 'Total price, RUB',
  vat_rub: 'VAT (20%), RUB',
  quantity: 'Quantity',
  profile: 'Profile',
  login: 'Login',
  first_name: 'First name',
  last_name: 'Last name',
  company_name: 'Company name',
  company_ownership: 'Type of ownership',
  country_phone: 'Country and phone number',
  inn: 'Tax number',
  kpp: 'Additional tax number',
  set_password: 'Password',
  confirm_password: 'Confirm password',
  news: 'News',
  all_news: 'All news',
  orders: 'Orders',
  favourites: 'Favourite products',
  to_homepage: 'To homepage',
  cart: 'Cart',
  go_to_cart: 'Go to cart',
  express: 'Express',
  prev_btn: 'Previous',
  next_btn: 'Next',
  price_requests: 'My requests',
  search_history: 'Search history',
  print: 'Print',
  save: 'Save',
  specialization: 'Specialization',
  where_to_buy: 'Where to buy?',
  list_of_distributors: 'List of distributors',
  download_order_file: 'Download order file',
}
