<i18n lang="yaml">
ru:
  select_city: 'Выберите город'
en:
  select_city: 'Select city'
</i18n>

<template>
  <div v-if="!loading" class="container">
    <breadcrumbs :breadcrumbs="breadcrumbs" />
    <h1>{{ $t('where_to_buy') }} {{ $t('list_of_distributors') }}</h1>
    <span>{{ $t('select_city') }}</span>
    <div class="filters-addresses">
      <select-city v-if="allCities.length" :city="city" :cities="allCities" @change="changeCity"/>
<!--      <select-specialization />-->
      <div class="filter-elem3">
        <a id="map-tab-id" :class="{'active': modeShowMap}" @click="changeMode(true)">На карте</a>
        <a id="list-tab-id" :class="{'active': !modeShowMap}" @click="changeMode(false)">Списком</a>
      </div>
    </div>
    <div class="tab-content scroll-to" ref="companies_list">
      <transition name="fade" mode="out-in">
        <div v-if="modeShowMap" class="tab-pane map-tab container">
          <transition name="fade" mode="out-in">
            <div v-if="!filteredCompanies.length" :key="transitionKey" class="companies-no">
              <span>Рядом с Вами нет дистрибьюторов по выбранным фильтрам. Выберите другой город.</span>
            </div>
            <companies-map v-else :key="transitionKey" :companies="filteredCompanies" :city="city"/>
          </transition>
        </div>
        <div v-else class="tab-pane list-tab container">
          <transition name="fade" mode="out-in">
            <div :key="transitionKey" id="list-id" class="list-class">
              <div v-if="!filteredCompanies.length" class="companies-no">
                <span>Рядом с Вами нет дистрибьюторов по выбранным фильтрам. Выберите другой город.</span>
              </div>
              <company-block v-else v-for="company in filteredCompaniesPerPage" :key="company.id" :company="company"/>
              <div class="pagination">
                <paginator v-if="filteredCompanies.length" :key="transitionKey" :page="pageNum"
                           :items-per-page="companiesPerPage" :total-items="filteredCompanies.length"
                           @change-page="changePage"/>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import Breadcrumbs from "@/components/breadcrumbs";

const _userLocationCookieName = 'user-location-coordinates';
const _navigatorGeolocationTimeout = 1;
const _companiesPerPage = 10;

import CompaniesMap from "@/components/where-to-buy/companies-map";
import CompanyBlock from "@/components/where-to-buy/company-block";
import Paginator from '@/components/paginator';
import SelectCity from '@/components/where-to-buy/select-city';
import SelectSpecialization from '@/components/where-to-buy/select-specialization';

export default {
  components: {
    Breadcrumbs,
    CompaniesMap,
    CompanyBlock,
    Paginator,
    SelectCity,
    SelectSpecialization
  },
  data() {
    return {
      loading: true,
      modeShowMap: true,
      pageNum: 1,
      allCities: [],
      allCompanies: [],
      city: null,
    }
  },
  computed: {
    breadcrumbs() {
      let breadcrumbs = [];
      breadcrumbs.push({
        name: this.$t('where_to_buy'),
        link: '',
      });
      return breadcrumbs;
    },
    companiesPerPage() {
      return _companiesPerPage;
    },
    filteredCompanies() {
      let list = this.allCompanies;
      if (this.city) {
        list = list.filter(x => x.city_display_part === this.city[0]);
      }
      return list;
    },
    filteredCompaniesPerPage() {
      if (this.pageNum) {
        return this.filteredCompanies.slice(this.companiesPerPage * (this.pageNum - 1), this.companiesPerPage * this.pageNum);
      }
      return this.filteredCompanies;
    },
    transitionKey() {
      return [
        this.pageNum || 0,
        this.city ? this.city[1] : '',
      ].join('-');
    },
  },
  mounted() {
    this.$store.dispatch('addLoading');
    if (this.$route.params.city) {
      this.getData();
    } else {
      this.getUserLocation(this.getData);
    }
    const app = this;
    window.onpopstate = function(e) {
      app.loadPageState();
    }
  },
  methods: {
    getUserLocation(callback) {
      let coords = this.$cookies.get(_userLocationCookieName);
      if (coords) {
        try {
          callback(coords);
        } catch (e) {
          callback();
        }
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              coords = {
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
              };
              this.$cookies.set(_userLocationCookieName, coords);
              callback(coords);
            },
            () => callback(),
            {timeout: _navigatorGeolocationTimeout},
          )
        } else {
          callback();
        }
      }
    },
    getData(coords = null) {
      this.$store.dispatch('removeLoading');
      let params = {};
      if (coords) {
        params = {...coords};
      }
      this.$axios.$get('/user/branch/addresses/', {params: params, count_loading: true})
        .then((response) => {
          this.allCities = response.cities;
          this.allCompanies = response.companies;
          if (history.state.pageParams) {
            this.loadPageState();
          } else if (this.$route.params.city && this.allCities.length) {
            this.changeCity(this.allCities.find(x => x[1] === this.$route.params.city), false);
          } else if (response.closest_city) {
            this.savePageState();
            this.changeCity(response.closest_city);
          }
        }).catch(() => {}).finally(() => this.loading = false);
    },
    historyPushState() {
      const newRoute = this.$router.resolve({
        path: this.localePath(this.city ? `/where-to-buy/${this.city[1]}/` : '/where-to-buy/'),
        params: {page: this.$route.params.page},
      });
      history.pushState({}, null, newRoute.href);
    },
    changeCity(city, setHistory = true) {
      this.city = city;
      this.pageNum = 1;
      if (setHistory) {
        this.historyPushState();
      }
      this.savePageState();
    },
    changeMode(val) {
      this.modeShowMap = val;
      this.historyPushState();
      this.savePageState();
    },
    changePage(page) {
      this.pageNum = page;
      if (this.$refs.companies_list) {
        this.$refs.companies_list.scrollIntoView({behavior: 'smooth'});
      }
      this.historyPushState();
      this.savePageState();
    },
    savePageState() {
      let state = history.state;
      state.pageParams = JSON.stringify({
        modeShowMap: this.modeShowMap,
        pageNum: this.pageNum,
        city: this.city,
      });
      history.replaceState(state, null);
    },
    loadPageState() {
      if (history.state.pageParams) {
        let params = JSON.parse(history.state.pageParams);
        this.modeShowMap = params.modeShowMap;
        this.pageNum = params.pageNum;
        this.city = params.city;
      }
    },
  },
}
</script>
