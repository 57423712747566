<i18n lang="yaml">
ru:
  prev_page: 'Предыдущая страница'
  next_page: 'Следующая страница'
  all_pages: 'Все'
en:
  prev_page: 'Previous page'
  next_page: 'Next page'
  all_pages: 'All'
</i18n>

<template>
  <div v-if="page && totalPages > 1">
      <div v-if="showPreviousPage && page > 1" key="prev">
        <a @click.prevent="$emit('change-page', page - 1)" :aria-label="$t('prev_page')">
          <i class="svg-icon arrow-paginator rotate-180"></i>
        </a>
      </div>
      <div v-for="i in visiblePages" :class="{'active': i && i === page}" :key="`page-${i}-${page}`">
        <span v-if="i && i === page">{{ i }}</span>
        <a v-else-if="i" @click.prevent="$emit('change-page', i)">{{ i }}</a>
        <a v-else @click.prevent="">...</a>
      </div>
      <div v-if="showNextPage && page < totalPages" key="next">
        <a @click.prevent="$emit('change-page', page + 1)" :aria-label="$t('next_page')">
          <i class="svg-icon arrow-paginator"></i>
        </a>
      </div>
      <div v-if="showAllPage && totalPages > 1" key="all">
        <a @click.prevent="$emit('change-page', null)">{{ $t('all_pages') }}</a>
      </div>
  </div>
  <div v-else></div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    pageMeasure: {
      type: Number,
      default: 10,
    },
    pagesNearCurrent: {
      type: Number,
      default: 5,
    },
    showPreviousPage: {
      type: Boolean,
      default: true,
    },
    showNextPage: {
      type: Boolean,
      default: true,
    },
    showAllPage: {
      type: Boolean,
      default: true,
    },
    totalItems: Number,
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    visiblePages() {
      const pagesAroundCurrent = Math.floor(this.pagesNearCurrent / 2);
      const midPagePos1 = this.page - pagesAroundCurrent;
      const midPagePos2 = this.page + pagesAroundCurrent;
      const endPagePos = this.totalPages - this.pagesNearCurrent;
      let pagesList = [1, this.page, this.totalPages];
      // add pages multiple by page measure
      for (let i = this.pageMeasure; i <= this.totalPages; i += this.pageMeasure) {
        pagesList.push(i);
      }
      // add pages around current (if current page is in the middle of the list)
      for (let i = midPagePos1; 1 <= i && i <= midPagePos2 && i <= this.totalPages; i++) {
        pagesList.push(i);
      }
      // add pages around current (if current page is in the beginning of the list)
      for (let i = 1; i <= this.totalPages && this.page <= this.pagesNearCurrent && i <= this.pagesNearCurrent; i++) {
        pagesList.push(i);
      }
      // add pages around current (if current page is in the end of the list)
      for (let i = this.totalPages; 1 <= i && endPagePos < this.page && endPagePos < i; i--) {
        pagesList.push(i);
      }
      pagesList = [...new Set(pagesList)].sort((a, b) => a - b);
      // add dots around subset surrounding current page
      let currentPagePos = pagesList.indexOf(this.page);
      let dotsBefore = Math.min(...pagesList.filter((x, i) => i <= currentPagePos && (pagesList[i + 1] - x) === 1));
      if (dotsBefore > 1) {
        pagesList.splice(pagesList.indexOf(dotsBefore), 0, null);
      }
      let dotsAfter = Math.max(...pagesList.filter((x, i) => i >= currentPagePos && (x - pagesList[i - 1]) === 1));
      if (dotsAfter < this.totalPages) {
        pagesList.splice(pagesList.indexOf(dotsAfter) + 1, 0, null);
      }
      return pagesList;
    },
  },
}
</script>
