<template>
  <div class="company-elem">
    <nuxt-link :to="localePath(`/where-to-buy/company/${company.id}/`)" class="company-name">{{ company.name
      }}</nuxt-link>
    <div class="company-content">
<!--      <div class="company-info specs">-->
<!--        <span class="specs-header">{{ $t('specialization')}}</span>-->
<!--        <div>-->
<!--          <i class="svg-icon passenger-car"></i>-->
<!--          <i class="svg-icon commercial-car"></i>-->
<!--          <i class="svg-icon special-car"></i>-->
<!--        </div>-->
<!--      </div>-->
      <template v-if="company.address">
        <div class="company-info">
          <div>
            <i class="svg-icon location"></i>
            <span>{{ company.address }}</span>
          </div>
        </div>
        <div class="company-info">
          <div v-if="company.phone">
            <i class="svg-icon phone"></i>
            <a :href="'tel:' + company.phone">{{ company.phone }}</a>
          </div>
          <div v-if="company.site">
            <i class="svg-icon site"></i>
            <a :href="company.site" target="_blank">{{ company.site }}</a>
          </div>
        </div>
      </template>
      <template v-else-if="company.site">
        <div class="company-info">
          <div v-if="company.site">
            <i class="svg-icon site"></i>
            <a :href="company.site" target="_blank">{{ company.site }}</a>
          </div>
        </div>
        <div class="company-info">
          <div v-if="company.phone">
            <i class="svg-icon phone"></i>
            <a :href="'tel:' + company.phone">{{ company.phone }}</a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
export default {
  props: ['company'],
}
</script>
