<template>
  <div class="filter-elem2">
    <div class="dropdown-spec">
      <span>{{ selectedSpec ? selectedSpec: $t('specialization')}}</span>
      <button @click="toggleSwitch">
        <i class="svg-icon arrow-mobile2" :class="{'rotate-180': active}"></i>
      </button>
    </div>
    <slide-up-down :active="active" :duration="400" :responsive="true" class="filter-specs"
                   @close-end="changeSpecialization">
        <div ref="spec_list" class="spec-list">
          <div v-for="specialization in specializations">
            <a @click="selectSpecialization(specialization)">
              <span class="spec-elem">{{ specialization }}</span>
            </a>
          </div>
        </div>
    </slide-up-down>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';

export default {
  components: {
    SlideUpDown,
  },
  data() {
    return {
      active: false,
      selectedSpec: '',
      specializations: [
        'Все типы ТС',
        'Легковые ТС',
        'Коммерческие ТС',
        'Спецтехника'
      ]
    }
  },
  watch: {
    spec(val) {
      this.selectedSpec = val;
    },
  },
  methods: {
    toggleSwitch() {
      this.active = !this.active;
    },
    changeSpecialization() {
      this.$emit('change', this.selectedSpec);
      if (this.$refs.spec_list_list) {
        this.$refs.spec_list.scrollTop = 0;
      }
    },
    selectSpecialization(spec) {
      this.selectedSpec = spec;
      this.toggleSwitch();
    },
  },
}
</script>
